import { createContext } from 'react'
export const initSession = {
  version: 0,
  recentlySubmitted: [],
  atfalList: [],
  lastFetch: '2021-11-10',
}

const defaultContext = {
  session: null,
  dispatch: () => {},
}

export default createContext(defaultContext)
