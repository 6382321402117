export const fetchAtfalList = function (setAtfalList, setNotification) {
  var API_URL = 'https://laxh9d939f.execute-api.ap-southeast-1.amazonaws.com/default/mkamu-atfaal-register'
  var API_KEY = '763rmz0Dhl5eu1Tyiv6gc54hN6tbfBfR53G6KjoF'

  fetch(API_URL, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'X-Api-Key': API_KEY,
    },
  })
    .then((res) => {
      return res.json()
    })
    .then(setAtfalList, () => {
      setNotification({ message: 'Failed to fetch atfal list', accent: 'error', duration: 7000 })
    })
}

export const submitWeeklyReport = function (request, setNotification, onSuccess) {
  const BACKEND_URL =
    'https://script.google.com/macros/s/AKfycbz78ZKLGZuETxNMdMa0P9S1KQG7ZCVBStDgFUzAnoCNRf2W0qY_TtNutm6jAf0dMHDe5Q/exec'
  fetch(BACKEND_URL, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    body: JSON.stringify(request),
  })
    .then((res) => {
      return res.json()
    })
    .then(
      (e) => {
        if (e.result === 'error') {
          setNotification({ message: 'Failed to submit weekly report', accent: 'error', duration: 7000 })
        } else {
          onSuccess()
        }
      },
      () => {
        setNotification({ message: 'Failed to submit weekly report', accent: 'error', duration: 7000 })
      }
    )
}
