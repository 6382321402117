import upd from 'immer'
import { DateTime } from 'luxon'
export default (session, action) => {
  const nextState = upd(session, (draft) => {
    switch (action.type) {
      case 'setAtfalList':
        draft.atfalList = action.atfalList
        draft.lastFetch = DateTime.now().toISODate()
        break
      case 'updateRecentlySubmitted':
        if (draft.recentlySubmitted.find((v) => v.id === action.tifl.id) === undefined) {
          draft.recentlySubmitted = [...draft.recentlySubmitted, action.tifl]
        }
        break
      case 'removeFromRecentlySubmitted':
        draft.recentlySubmitted = [...draft.recentlySubmitted.filter((v) => v.id !== action.tifl.id)]
        break
    }
  })
  localStorage.setItem('atfal-website-session', JSON.stringify(nextState))
  return nextState
}
