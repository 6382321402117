import React from 'react'

const Notifications = ({ notifications }) => {
  return (
    <div css='position: relative;'>
      <div css='position: fixed; top: 1rem; left: 0; right: 1rem; display: flex; flex-direction: column; align-items: end; gap: 0.75rem;'>
        {notifications.map((n) => (
          <div
            key={n.id}
            $accent={n.accent || 'black'}
            css={`
              background: ${(props) => props.theme.color.white};
              padding: 0.5rem 1rem;
              border-radius: 5pt;
              box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
              border-left: 2.5pt solid ${(props) => props.theme.color[props.$accent]};
              border-right: 2.5pt solid ${(props) => props.theme.color[props.$accent]};
            `}
          >
            {n.message}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Notifications
