import { DateTime } from 'luxon'
import React, { lazy, Suspense, useEffect, useReducer, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { fetchAtfalList } from './api'

import Load from './assets/sync-alt-solid.svg'
import NotificationContext from './NotificationContext'
import Notifications from './Notifications'
import SessionContext, { initSession } from './SessionContext'
import sessionReducer from './sessionReducer'

import { spin } from './simplified-components/styledElements'

const Home = lazy(() => import('./routes/Home'))
const WeeklyReport = lazy(() => import('./routes/WeeklyReport'))

const Fallback = () => (
  <div css='display: flex; align-items:center; justify-content: center; height: 100vh; '>
    {/* Font Awesome Icon used under https://fontawesome.com/license */}
    <Load
      css={`
        ${spin}
        height: 3rem;
        width: 3rem;
      `}
    />
  </div>
)

const Router = () => {
  let cachedSession = JSON.parse(localStorage.getItem('atfal-website-session')) || initSession
  if (cachedSession.version != initSession.version) {
    /* reset session if a new version is needed - avoids unstable state. 
    shouldn't cause any issue if we are pulling info from API */
    cachedSession = initSession
    localStorage.setItem('atfal-website-session', JSON.stringify(cachedSession))
  }
  const [session, dispatch] = useReducer(sessionReducer, cachedSession)

  const [notifications, _setNotifications] = useState([])

  /**
   * Shows a notification for 3s (default).
   * @param {string} message - string message to show
   * @param {number} duration - duration in milliseconds
   * @param {string} accent - a color defined in the styled-component's theme.
   */
  const setNotification = ({ message, accent = 'black', duration = 3000 }) => {
    const id = message + DateTime.now().toISO()
    _setNotifications((notifications) => [...notifications, { id, message, accent }])
    setTimeout(() => _setNotifications((notifications) => [...notifications.filter((n) => n.id !== id)]), duration)
  }

  useEffect(() => {
    if (DateTime.now().diff(DateTime.fromISO(session.lastFetch), 'days').days > 2) {
      fetchAtfalList((l = []) => {
        l.sort((a, b) => a.name.localeCompare(b.name))
        dispatch({
          type: 'setAtfalList',
          atfalList: l,
        })
      }, setNotification)
    }
  }, [])

  return (
    <NotificationContext.Provider value={{ setNotification }}>
      <SessionContext.Provider value={{ session, dispatch }}>
        <Notifications notifications={notifications} />
        <BrowserRouter>
          <Routes>
            <Route
              path='/'
              element={
                <Suspense fallback={<Fallback />}>
                  <Home />
                </Suspense>
              }
            />
            <Route
              path='/submitted'
              element={
                <Suspense fallback={<Fallback />}>
                  <Home submitted={true}/>
                </Suspense>
              }
            />
            <Route
              path='/report'
              element={
                <Suspense fallback={<Fallback />}>
                  <WeeklyReport />
                </Suspense>
              }
            />
          </Routes>
        </BrowserRouter>
      </SessionContext.Provider>
    </NotificationContext.Provider>
  )
}

export default Router
