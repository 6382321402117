import styled, { css } from 'styled-components'

export const Label = styled.label`
  color: ${({ theme }) => theme.color.label};
  font-family: ${({ theme }) => theme.font.family.label};
  font-size: ${({ theme }) => theme.font.size.label};
  display: block;
  margin-bottom: 0.25rem;
`

export const Description = styled.span`
  margin-top: 0.4rem;
  font-family: ${({ theme }) => theme.font.family.regular};
  color: ${({ theme }) => theme.color.textSecondary};
  font-size: ${({ theme }) => theme.font.size.extraSmall};
  display: block;
`

export const ErrorMessage = styled(Description)`
  padding-left: 2rem;
  color: ${({ theme }) => theme.color.error};
`

export const DisplayOnlyText = styled.div`
  font-size: ${({ theme }) => theme.font.size.normal};
  font-family: ${({ theme }) => theme.font.family.regular};
  color: ${({ theme }) => theme.color.success};
  border-width: 0;
  border-bottom-width: 1px;
  border-bottom-color: transparent;
  display: block;
  margin-top: ${(props) => (props.label ? '0.125rem' : '0')};
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: 100%;
  height: 1.5rem;
`

/**
 * Adds spin animation
 */
export const spin = css`
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`

export function onDesktop(cssStrings, ...interpolations) {
  return css`
    @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
      ${css(cssStrings, ...interpolations)}
    }
  `
}
export const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  background: ${(props) => props.theme.color.background};
  width: 100%;
  max-width: 414px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;

  @media (min-width: 414px) {
    max-width: min(calc(100vw), 414px);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    height: auto;
    padding: 0;
    max-width: min(calc(100vw - 6rem), 780px);
    margin-top: 3rem;
    margin-bottom: 1.5rem;
    border-radius: 5px;
  }
`

export const DescriptionBar = styled.div`
  background-color: ${(props) => props.theme.color.primary};
  color: ${(props) => props.theme.color.white};
  padding: 1rem;
  ${onDesktop`padding: 1.5rem 2rem;`}
`

export const buttonStyles = {
  normal: {
    backgroundColor: 'primary',
    borderColor: 'primary',
    titleColor: 'white',
    descriptionColor: 'muted',
    textAlign: 'center',
    onHoverTextColor: 'black',
    onHoverBorderColor: 'accent',
    onHoverBackgroundColor: 'accent',
    cssForTextWrapper: ['width:100%;'],
  },
  smallOutlined: {
    buttonSize: 'small',
    backgroundColor: 'background',
    borderColor: 'primary',
    titleColor: 'primary',
    descriptionColor: 'muted',
    textAlign: 'center',
    onHoverTextColor: 'white',
    onHoverBorderColor: 'accent',
    onHoverBackgroundColor: 'accent',
    cssForTextWrapper: ['width:100%;'],
  },
  borderless: {
    buttonType: 'borderless',
    buttonSize: 'extra-small',
    borderRadius: '0',
    titleColor: 'textSecondary',
    titleFontSize: 'extraSmall',
    textAlign: 'center',
    onHoverTextColor: 'black',
    cssForTextWrapper: ['width:100%;'],
  },
}
